import { SocketChannel } from '~/modules/SDK/socket/SocketChannel';
/**
 * Symbols Runtime Config
 *
 * ## 當需要更新此列表時，只需要跑以下腳本來得到最新的資訊
 *
 * 如果需要更多 Symbol 資訊，可以參考
 * [gist/7c496dde6a29d483e9f83620011a847a](https://gist.github.com/Aqzhyi/7c496dde6a29d483e9f83620011a847a)
 *
 * @example <caption>A Script that can fetch the new data</caption>
 *   let remoteSupportSymbols = await fetch(
 *     'https://tvapiws.d05.futures-op.com/search?limit=100&query=&type=all_types&exchange=',
 *   ).then(res => res.json())
 *
 *   let foo = _.keyBy(
 *     remoteSupportSymbols
 *       .filter(value => value.type !== 'option')
 *       .map(value => camelizeKeys(value)),
 *     'symbol',
 *   )
 *
 *   console.info(foo)
 *
 * @example <caption>要加小數點精確度的話</caption>
 *   await fetch('https://tvapiws.futures-op.com/v2/symbols?symbol=ES-1').then(res => res.json())
 */
export const remoteSupportSymbols = {
    '0050': {
        description: '台灣50',
        exchange: 'TFE',
        fullName: '0050',
        pricescale: 100,
        symbol: '0050',
        type: SocketChannel.tw_stocks_50,
    },
    '0056': {
        description: '元大高股息',
        exchange: 'TFE',
        fullName: '0056',
        pricescale: 100,
        symbol: '0056',
        type: SocketChannel.tw_stocks_50,
    },
    '00632R': {
        description: '台灣50反1',
        exchange: 'TFE',
        fullName: '00632R',
        pricescale: 100,
        symbol: '00632R',
        type: SocketChannel.tw_stocks_50,
    },
    '00677U': {
        description: '富邦VIX',
        exchange: 'TFE',
        fullName: '00677U',
        pricescale: 100,
        symbol: '00677U',
        type: SocketChannel.tw_stocks_50,
    },
    '1101': {
        description: '台泥',
        exchange: 'TFE',
        fullName: '1101',
        symbol: '1101',
        type: SocketChannel.tw_stocks_50,
    },
    '1102': {
        description: '亞泥',
        exchange: 'TFE',
        fullName: '1102',
        symbol: '1102',
        type: SocketChannel.tw_stocks_50,
    },
    '1216': {
        description: '統一',
        exchange: 'TFE',
        fullName: '1216',
        symbol: '1216',
        type: SocketChannel.tw_stocks_50,
    },
    '1301': {
        description: '台塑',
        exchange: 'TFE',
        fullName: '1301',
        symbol: '1301',
        type: SocketChannel.tw_stocks_50,
    },
    '1303': {
        description: '南亞',
        exchange: 'TFE',
        fullName: '1303',
        symbol: '1303',
        type: SocketChannel.tw_stocks_50,
    },
    '1326': {
        description: '台化',
        exchange: 'TFE',
        fullName: '1326',
        symbol: '1326',
        type: SocketChannel.tw_stocks_50,
    },
    '1402': {
        description: '遠東新',
        exchange: 'TFE',
        fullName: '1402',
        symbol: '1402',
        type: SocketChannel.tw_stocks_50,
    },
    '1590': {
        description: '亞德客-KY',
        exchange: 'TWSE',
        fullName: '1590',
        symbol: '1590',
        type: SocketChannel.tw_stocks_50,
    },
    '2002': {
        description: '中鋼',
        exchange: 'TFE',
        fullName: '2002',
        symbol: '2002',
        type: SocketChannel.tw_stocks_50,
    },
    '2105': {
        description: '正新',
        exchange: 'TFE',
        fullName: '2105',
        symbol: '2105',
        type: SocketChannel.tw_stocks_50,
    },
    '2207': {
        description: '和泰車',
        exchange: 'TFE',
        fullName: '2207',
        symbol: '2207',
        type: SocketChannel.tw_stocks_50,
    },
    '2301': {
        description: '光寶科',
        exchange: 'TFE',
        fullName: '2301',
        symbol: '2301',
        type: SocketChannel.tw_stocks_50,
    },
    '2303': {
        description: '聯電',
        exchange: 'TFE',
        fullName: '2303',
        symbol: '2303',
        type: SocketChannel.tw_stocks_50,
    },
    '2308': {
        description: '台達電',
        exchange: 'TFE',
        fullName: '2308',
        symbol: '2308',
        type: SocketChannel.tw_stocks_50,
    },
    '2317': {
        description: '鴻海',
        exchange: 'TFE',
        fullName: '2317',
        symbol: '2317',
        type: SocketChannel.tw_stocks_50,
    },
    '2327': {
        description: '國巨',
        exchange: 'TFE',
        fullName: '2327',
        symbol: '2327',
        type: SocketChannel.tw_stocks_50,
    },
    '2330': {
        description: '台積電',
        exchange: 'TFE',
        fullName: '2330',
        symbol: '2330',
        type: SocketChannel.tw_stocks_50,
    },
    '2345': {
        description: '智邦',
        exchange: 'TWSE',
        fullName: '2345',
        symbol: '2345',
        type: SocketChannel.tw_stocks_50,
    },
    '2357': {
        description: '華碩',
        exchange: 'TFE',
        fullName: '2357',
        symbol: '2357',
        type: SocketChannel.tw_stocks_50,
    },
    '2379': {
        description: '瑞昱',
        exchange: 'TWSE',
        fullName: '2379',
        symbol: '2379',
        type: SocketChannel.tw_stocks_50,
    },
    '2382': {
        description: '廣達',
        exchange: 'TFE',
        fullName: '2382',
        symbol: '2382',
        type: SocketChannel.tw_stocks_50,
    },
    '2395': {
        description: '研華',
        exchange: 'TFE',
        fullName: '2395',
        symbol: '2395',
        type: SocketChannel.tw_stocks_50,
    },
    '2408': {
        description: '南亞科',
        exchange: 'TFE',
        fullName: '2408',
        symbol: '2408',
        type: SocketChannel.tw_stocks_50,
    },
    '2409': {
        description: '友達',
        exchange: 'TFE',
        fullName: '2409',
        symbol: '2409',
        type: SocketChannel.tw_stocks_50,
    },
    '2412': {
        description: '中華電',
        exchange: 'TFE',
        fullName: '2412',
        symbol: '2412',
        type: SocketChannel.tw_stocks_50,
    },
    '2454': {
        description: '聯發科',
        exchange: 'TFE',
        fullName: '2454',
        symbol: '2454',
        type: SocketChannel.tw_stocks_50,
    },
    '2474': {
        description: '可成',
        exchange: 'TFE',
        fullName: '2474',
        symbol: '2474',
        type: SocketChannel.tw_stocks_50,
    },
    '2603': {
        description: '長榮',
        exchange: 'TFE',
        fullName: '2603',
        symbol: '2603',
        type: SocketChannel.tw_stocks_50,
    },
    '2609': {
        description: '陽明',
        exchange: 'TFE',
        fullName: '2609',
        symbol: '2609',
        type: SocketChannel.tw_stocks_50,
    },
    '2615': {
        description: '萬海',
        exchange: 'TFE',
        fullName: '2615',
        symbol: '2615',
        type: SocketChannel.tw_stocks_50,
    },
    '2633': {
        description: '台灣高鐵',
        exchange: 'TFE',
        fullName: '2633',
        symbol: '2633',
        type: SocketChannel.tw_stocks_50,
    },
    '2801': {
        description: '彰銀',
        exchange: 'TFE',
        fullName: '2801',
        symbol: '2801',
        type: SocketChannel.tw_stocks_50,
    },
    '2823': {
        description: '中壽',
        exchange: 'TFE',
        fullName: '2823',
        symbol: '2823',
        type: SocketChannel.tw_stocks_50,
    },
    '2880': {
        description: '華南金',
        exchange: 'TFE',
        fullName: '2880',
        symbol: '2880',
        type: SocketChannel.tw_stocks_50,
    },
    '2881': {
        description: '富邦金',
        exchange: 'TFE',
        fullName: '2881',
        symbol: '2881',
        type: SocketChannel.tw_stocks_50,
    },
    '2882': {
        description: '國泰金',
        exchange: 'TFE',
        fullName: '2882',
        symbol: '2882',
        type: SocketChannel.tw_stocks_50,
    },
    '2883': {
        description: '開發金',
        exchange: 'TFE',
        fullName: '2883',
        symbol: '2883',
        type: SocketChannel.tw_stocks_50,
    },
    '2884': {
        description: '玉山金',
        exchange: 'TFE',
        fullName: '2884',
        symbol: '2884',
        type: SocketChannel.tw_stocks_50,
    },
    '2885': {
        description: '元大金',
        exchange: 'TFE',
        fullName: '2885',
        symbol: '2885',
        type: SocketChannel.tw_stocks_50,
    },
    '2886': {
        description: '兆豐金',
        exchange: 'TFE',
        fullName: '2886',
        symbol: '2886',
        type: SocketChannel.tw_stocks_50,
    },
    '2887': {
        description: '台新金',
        exchange: 'TFE',
        fullName: '2887',
        symbol: '2887',
        type: SocketChannel.tw_stocks_50,
    },
    '2888': {
        description: '新光金',
        exchange: 'TFE',
        fullName: '2888',
        symbol: '2888',
        type: SocketChannel.tw_stocks_50,
    },
    '2890': {
        description: '永豐金',
        exchange: 'TFE',
        fullName: '2890',
        symbol: '2890',
        type: SocketChannel.tw_stocks_50,
    },
    '2891': {
        description: '中信金',
        exchange: 'TFE',
        fullName: '2891',
        symbol: '2891',
        type: SocketChannel.tw_stocks_50,
    },
    '2892': {
        description: '第一金',
        exchange: 'TFE',
        fullName: '2892',
        symbol: '2892',
        type: SocketChannel.tw_stocks_50,
    },
    '2912': {
        description: '統一超',
        exchange: 'TFE',
        fullName: '2912',
        symbol: '2912',
        type: SocketChannel.tw_stocks_50,
    },
    '3008': {
        description: '大立光',
        exchange: 'TFE',
        fullName: '3008',
        symbol: '3008',
        type: SocketChannel.tw_stocks_50,
    },
    '3034': {
        description: '聯詠',
        exchange: 'TWSE',
        fullName: '3034',
        symbol: '3034',
        type: SocketChannel.tw_stocks_50,
    },
    '3037': {
        description: '欣興',
        exchange: 'TWSE',
        fullName: '3037',
        symbol: '3037',
        type: SocketChannel.tw_stocks_50,
    },
    '3045': {
        description: '台灣大',
        exchange: 'TFE',
        fullName: '3045',
        symbol: '3045',
        type: SocketChannel.tw_stocks_50,
    },
    '3711': {
        description: '日月光',
        //日月光投控
        exchange: 'TFE',
        fullName: '3711',
        symbol: '3711',
        type: SocketChannel.tw_stocks_50,
    },
    '4904': {
        description: '遠傳',
        exchange: 'TFE',
        fullName: '4904',
        symbol: '4904',
        type: SocketChannel.tw_stocks_50,
    },
    '4938': {
        description: '和碩',
        exchange: 'TFE',
        fullName: '4938',
        symbol: '4938',
        type: SocketChannel.tw_stocks_50,
    },
    '5871': {
        description: '中租-KY',
        exchange: 'TFE',
        fullName: '5871',
        symbol: '5871',
        type: SocketChannel.tw_stocks_50,
    },
    '5876': {
        description: '上海商銀',
        exchange: 'TFE',
        fullName: '5876',
        symbol: '5876',
        type: SocketChannel.tw_stocks_50,
    },
    '5880': {
        description: '合庫金',
        exchange: 'TFE',
        fullName: '5880',
        symbol: '5880',
        type: SocketChannel.tw_stocks_50,
    },
    '6415': {
        description: '矽力-KY',
        exchange: 'TWSE',
        fullName: '6415',
        symbol: '6415',
        type: SocketChannel.tw_stocks_50,
    },
    '6505': {
        description: '台塑化',
        exchange: 'TFE',
        fullName: '6505',
        symbol: '6505',
        type: SocketChannel.tw_stocks_50,
    },
    '6669': {
        description: '緯穎',
        exchange: 'TFE',
        fullName: '6669',
        symbol: '6669',
        type: SocketChannel.tw_stocks_50,
    },
    '8046': {
        description: '南電',
        exchange: 'TFE',
        fullName: '8046',
        symbol: '8046',
        type: SocketChannel.tw_stocks_50,
    },
    '9904': {
        description: '寶成',
        exchange: 'TFE',
        fullName: '9904',
        symbol: '9904',
        type: SocketChannel.tw_stocks_50,
    },
    '9910': {
        description: '豐泰',
        exchange: 'TFE',
        fullName: '9910',
        symbol: '9910',
        type: SocketChannel.tw_stocks_50,
    },
    'AD-1': {
        description: '澳幣',
        exchange: 'CME',
        fullName: 'AD-1',
        symbol: 'AD-1',
        type: SocketChannel.os_futures,
    },
    'BO-1': {
        description: '黄豆油',
        exchange: 'CBT',
        fullName: 'BO-1',
        symbol: 'BO-1',
        type: SocketChannel.os_futures,
    },
    'BP-1': {
        description: '英鎊',
        exchange: 'CME',
        fullName: 'BP-1',
        symbol: 'BP-1',
        type: SocketChannel.os_futures,
    },
    BTCUSDT: {
        description: '比幣特',
        exchange: 'TWSE',
        fullName: 'BTCUSDT',
        pricescale: 100,
        symbol: 'BTCUSDT',
        type: SocketChannel.os_futures,
    },
    'C-1': {
        description: '玉米',
        exchange: 'CBT',
        fullName: 'C-1',
        symbol: 'C-1',
        type: SocketChannel.os_futures,
    },
    'CC-1': {
        description: '可可',
        exchange: 'IUS',
        fullName: 'CC-1',
        pricescale: 100,
        symbol: 'CC-1',
        type: SocketChannel.os_futures,
    },
    'CD-1': {
        description: '加幣',
        exchange: 'CME',
        fullName: 'CD-1',
        symbol: 'CD-1',
        type: SocketChannel.os_futures,
    },
    'CL-1': {
        description: '輕原油',
        exchange: 'NYM',
        fullName: 'CL-1',
        symbol: 'CL-1',
        type: SocketChannel.os_futures,
    },
    'CN-1': {
        description: '中國A50',
        exchange: 'SGX',
        fullName: 'CN-1',
        symbol: 'CN-1',
        type: SocketChannel.os_futures,
    },
    'DX-1': {
        description: '美元指數',
        exchange: 'IUS',
        fullName: 'DX-1',
        pricescale: 1000,
        symbol: 'DX-1',
        type: SocketChannel.os_futures,
    },
    'EC-1': {
        description: '歐元',
        exchange: 'CME',
        fullName: 'EC-1',
        symbol: 'EC-1',
        type: SocketChannel.os_futures,
    },
    'ES-1': {
        description: '小S&P500',
        exchange: 'CME',
        fullName: 'ES-1',
        pricescale: 100,
        symbol: 'ES-1',
        type: SocketChannel.os_futures,
    },
    'ES-2103': {
        description: '小S&P500-202103',
        exchange: 'CME',
        fullName: 'ES-2103',
        pricescale: 100,
        symbol: 'ES-2103',
        type: SocketChannel.os_futures,
    },
    'GC-1': {
        description: '黃金',
        exchange: 'NYM',
        fullName: 'GC-1',
        pricescale: 10,
        symbol: 'GC-1',
        type: SocketChannel.os_futures,
    },
    'HCEI-1': {
        description: 'H股',
        exchange: 'HKEX',
        fullName: 'HCEI-1',
        symbol: 'HCEI-1',
        type: SocketChannel.os_futures,
    },
    'HG-1': {
        description: '高級銅',
        exchange: 'NYM',
        fullName: 'HG-1',
        symbol: 'HG-1',
        type: SocketChannel.os_futures,
    },
    'HSI-1': {
        description: '恆生',
        exchange: 'HKEX',
        fullName: 'HSI-1',
        symbol: 'HSI-1',
        type: SocketChannel.os_futures,
    },
    'IN-1': {
        description: '印度',
        exchange: 'SGX',
        fullName: 'IN-1',
        symbol: 'IN-1',
        type: SocketChannel.os_futures,
    },
    'JY-1': {
        description: '日圓',
        exchange: 'CME',
        fullName: 'JY-1',
        symbol: 'JY-1',
        type: SocketChannel.os_futures,
    },
    'TWN-1': {
        description: '富台指',
        exchange: 'SGX',
        fullName: 'TWN-1',
        symbol: 'TWN-1',
        type: SocketChannel.os_futures,
    },
    'KC-1': {
        description: '咖啡',
        exchange: 'IUS',
        fullName: 'KC-1',
        symbol: 'KC-1',
        type: SocketChannel.os_futures,
    },
    'MTX-1': {
        description: '小台指',
        exchange: 'TFE',
        fullName: 'MTX-1',
        symbol: 'MTX-1',
        type: SocketChannel.tw_futures,
    },
    'NE-1': {
        description: '紐幣',
        exchange: 'CME',
        fullName: 'NE-1',
        symbol: 'NE-1',
        type: SocketChannel.os_futures,
    },
    'NG-1': {
        description: '天然氣',
        exchange: 'NYM',
        fullName: 'NG-1',
        symbol: 'NG-1',
        type: SocketChannel.os_futures,
    },
    'NK-1': {
        description: '日經',
        exchange: 'SGX',
        fullName: 'NK-1',
        pricescale: 1,
        symbol: 'NK-1',
        type: SocketChannel.os_futures,
    },
    'NQ-1': {
        description: '小那斯達克',
        exchange: 'CME',
        fullName: 'NQ-1',
        pricescale: 100,
        symbol: 'NQ-1',
        type: SocketChannel.os_futures,
    },
    'NQ-2103': {
        description: '小那斯達克-202103',
        exchange: 'CME',
        fullName: 'NQ-2103',
        pricescale: 100,
        symbol: 'NQ-2103',
        type: SocketChannel.os_futures,
    },
    OTCA: {
        description: '櫃買指數',
        exchange: 'TFE',
        fullName: 'OTCA',
        symbol: 'OTCA',
        type: SocketChannel.tw_futures,
    },
    'PL-1': {
        description: '白金',
        exchange: 'NYM',
        fullName: 'PL-1',
        symbol: 'PL-1',
        type: SocketChannel.os_futures,
    },
    'RTY-1': {
        description: '小羅素',
        exchange: 'CME',
        fullName: 'RTY-1',
        symbol: 'RTY-1',
        type: SocketChannel.os_futures,
    },
    'S-1': {
        description: '黃豆',
        exchange: 'CBT',
        fullName: 'S-1',
        symbol: 'S-1',
        type: SocketChannel.os_futures,
    },
    'SB-1': {
        description: '11號糖',
        exchange: 'IUS',
        fullName: 'SB-1',
        symbol: 'SB-1',
        type: SocketChannel.os_futures,
    },
    'SI-1': {
        description: '白銀',
        exchange: 'CMX',
        fullName: 'SI-1',
        symbol: 'SI-1',
        type: SocketChannel.os_futures,
    },
    'TE-1': {
        description: '電子期',
        exchange: 'TFE',
        fullName: 'TE-1',
        symbol: 'TE-1',
        type: SocketChannel.tw_futures,
    },
    'TF-1': {
        description: '金融期',
        exchange: 'TFE',
        fullName: 'TF-1',
        symbol: 'TF-1',
        type: SocketChannel.tw_futures,
    },
    TSE13: {
        description: '電子指數',
        exchange: 'TFE',
        fullName: 'TSE13',
        symbol: 'TSE13',
        type: SocketChannel.tw_futures,
    },
    TSE17: {
        description: '金融指數',
        exchange: 'TFE',
        fullName: 'TSE17',
        symbol: 'TSE17',
        type: SocketChannel.tw_futures,
    },
    TSEA: {
        description: '加權指數',
        exchange: 'TFE',
        fullName: 'TSEA',
        symbol: 'TSEA',
        type: SocketChannel.tw_futures,
    },
    TWXI: {
        description: '非金電指數',
        exchange: 'TFE',
        fullName: 'TWXI',
        symbol: 'TWXI',
        type: SocketChannel.tw_futures,
    },
    'TX-1': {
        description: '台指期',
        exchange: 'TFE',
        fullName: 'TX-1',
        pricescale: 1,
        symbol: 'TX-1',
        type: SocketChannel.tw_futures,
    },
    'TXAM-1': {
        description: '台指期(日盤)',
        exchange: 'TFE',
        fullName: 'TXAM-1',
        symbol: 'TXAM-1',
        type: SocketChannel.tw_futures,
    },
    'W-1': {
        description: '小麥',
        exchange: 'CBT',
        fullName: 'W-1',
        symbol: 'W-1',
        type: SocketChannel.os_futures,
    },
    'YM-1': {
        description: '小道瓊',
        exchange: 'CBT',
        fullName: 'YM-1',
        pricescale: 1,
        symbol: 'YM-1',
        type: SocketChannel.os_futures,
    },
    'YM-2103': {
        description: '小道瓊-202103',
        exchange: 'CBT',
        fullName: 'YM-2103',
        pricescale: 100,
        symbol: 'YM-2103',
        type: SocketChannel.os_futures,
    },
};
