export const chartingrc = {
    widgetOverridesForKbars: {
        light: {
            // 綠跌、紅漲、台式K棒
            'mainSeriesProperties.candleStyle.upColor': '#d75442',
            'mainSeriesProperties.candleStyle.downColor': '#6ba583',
            'mainSeriesProperties.candleStyle.borderUpColor': '#5b1a13',
            'mainSeriesProperties.candleStyle.borderDownColor': '#225437',
            'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
            'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
            'paneProperties.background': '#ffffff',
            //--
        },
        dark: {
            // 綠跌、紅漲、台式K棒
            'mainSeriesProperties.candleStyle.upColor': '#d75442',
            'mainSeriesProperties.candleStyle.downColor': '#6ba583',
            'mainSeriesProperties.candleStyle.borderUpColor': '#5b1a13',
            'mainSeriesProperties.candleStyle.borderDownColor': '#225437',
            'mainSeriesProperties.candleStyle.wickUpColor': 'rgba( 115, 115, 117, 1)',
            'mainSeriesProperties.candleStyle.wickDownColor': 'rgba( 115, 115, 117, 1)',
            'paneProperties.background': '#1e1e1e',
            //--
        },
    },
};
