import { useMediaQuery } from '@mui/material';
import json2mq from 'json2mq';
import { rwdrc } from '~/configs/rwdrc';
/**
 * @deprecated
 * @see {useMedia}
 */
export const useIsMobile = () => {
    const isMobile = useMediaQuery(json2mq({ maxWidth: rwdrc.sm.max }), { noSsr: true });
    return isMobile;
};
