import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
/**
 * 建立 Grid 或 GridArea 的 HTML 結構
 *
 * @example
 *   // 搭配 CSS Preset 讓關注點分離，不會全部都塞在 Page/Component 裡
 *
 *   // 用於排版的 Grid、Area 以及它們的 CSS
 *   // 以這裡為例，我要有一個 TopBar 的獨立排版
 *   const GridTopBar = createGridArea('GridTopBar')
 *   const GridUserAvatar = createGridArea('GridUserAvatar')
 *   const GridAgentIcon = createGridArea('GridAgentIcon')
 *   const GridTopBarCSS = css`
 *     .${GridTopBar.name} {
 *       grid-auto-rows: auto;
 *       grid-template-columns: 1fr 1fr;
 *       grid-template-areas: '${GridAgentIcon.name} ${GridUserAvatar.name}';
 *
 *       width: 100%;
 *       align-content: center;
 *       padding: 0 8px;
 *
 *       // 包在 GridTopBar.name 的 namespace 底下，避險相互干擾
 *       .${GridUserAvatar.name} {
 *         text-align: right;
 *       }
 *     }
 *   `
 *
 *   // 主要的 Page/Component
 *   const TestComponent: React.FC = props => {
 *     return (
 *       <Fragment>
 *         <Global
 *           styles={css`
 *             #__body {
 *               overflow: hidden;
 *             }
 *
 *             // 將 TopBar 獨立排版 CSS 找個地方（一般是最外層）塞進來 Global 生效
 *             ${GridTopBarCSS};
 *           `}
 *         ></Global>
 *         // 放置 TopBar 獨立結構
 *         <GridTopBar.Grid>
 *           <GridUserAvatar.Area />
 *
 *           <GridAgentIcon.Area />
 *         </GridTopBar.Grid>
 *       </Fragment>
 *     )
 *   }
 */
export const createGridArea = (name) => {
    const name_ = name.charAt(0).toUpperCase() + name.substring(1);
    return {
        name: name_,
        Grid: memo(function Grid(props) {
            return (<_GridAreaStyled className={`${props.className || ''} ${name_}`} {...props} name={name_} grid>
          {props.children}
        </_GridAreaStyled>);
        }),
        Area: memo(function Area(props) {
            return (<_GridAreaStyled className={`${props.className || ''} ${name_}`} {...props} name={name_}>
          {props.children}
        </_GridAreaStyled>);
        }),
    };
};
export const _GridAreaStyled = styled('div') `
  height: 100%;

  ${({ grid }) => grid
    ? css `
          display: grid;
        `
    : ``}

  ${({ name }) => name
    ? css `
          grid-area: ${name};
          label: GridArea ${name};
        `
    : css `
          label: GridArea;
        `};
`;
