import { maxBy } from 'lodash';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useInterval, useUnmount, useUpdateEffect } from 'react-use';
import { proxy, ref, useSnapshot } from 'valtio';
import { component } from '~/utils/component';
import dayAPI from '~/utils/dayAPI';
class DatafeedStatusModule {
    store;
    constructor() {
        this.store = proxy({
            /** 最後一次 K棒更新時間 */
            lastUpdatedAt: dayAPI(),
            /** 「多久沒有更新」就算它是閒置狀態？ */
            kbarsIdleMinutes: 15 * 1000 * 60,
            /** 更新現在是閒置中嗎？ */
            isKbarsIdle: null,
        });
    }
    analyzeKBars(kbars) {
        const latestBar = maxBy(kbars, 'time');
        if (!latestBar?.time)
            return;
        this.store.lastUpdatedAt =
            // `latestBar.time` 因為它沒有精確到秒，所以這邊以「client 最後接值的當前時間」為主
            dayAPI();
    }
    isNoUpdating() {
        return (this.store.isKbarsIdle = this.store.lastUpdatedAt.isBefore(dayAPI().subtract(this.store.kbarsIdleMinutes, 'milliseconds')));
    }
    /** 更新內在 state */
    useUpdateIdle = (intervalMs = 15000) => {
        useInterval(() => {
            this.isNoUpdating();
        }, intervalMs);
    };
    /** 檢查K棒是否在更新，並給予 UI 提示使用者 */
    useToastWhenIdle = () => {
        const state = useSnapshot(this.store);
        const toastId = useRef('');
        useUpdateEffect(() => {
            if (state.isKbarsIdle === true && !toastId.current) {
                toastId.current = toast.loading(`K棒報價圖表目前停止更新`, {
                    isLoading: true,
                    autoClose: false,
                });
            }
            else if (state.isKbarsIdle === false && toastId.current) {
                toast.dismiss(toastId.current);
                toastId.current = '';
            }
        }, [state.isKbarsIdle]);
        useUnmount(() => {
            toast.dismiss(toastId.current);
        });
    };
    LatestUpdated = ref(component(props => {
        const state = useSnapshot(this.store);
        return <div>圖表K棒最後更新於 {state.lastUpdatedAt.format('YYYY/MM/DD HH:mm:ss')}</div>;
    }, {
        displayName: `圖表K棒最後更新時間`,
    }));
}
/** Template 內建直接用 */
export const fr_datafeedStatus = proxy(new DatafeedStatusModule());
