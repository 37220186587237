import { eventEmitter } from '~/modules/SDK/Events/eventEmitter';
import { EventString } from '~/modules/SDK/Events/EventString';
/**
 * 電腦或手機，從休眠中醒過來時，使 Socket 得以重新連線
 *
 * @example <caption>非 React</caption>
 *   const unsub = socketOnSleepRecoverReconnect({ current: socket })
 *
 *   unsub()
 */
export const socketOnSleepRecoverReconnect = (socketClientRef) => {
    const handler = (data) => {
        if (socketClientRef.current?.disconnected && data.state === 'visible') {
            socketClientRef.current?.connect();
        }
    };
    eventEmitter.on(EventString.visibilityChange, handler);
    return function socketOnSleepRecoverReconnectUnsub() {
        eventEmitter.off(EventString.visibilityChange, handler);
    };
};
