import { UDFCompatibleDatafeed } from '~/public/charting_library_v19/datafeeds/udf/lib/udf-compatible-datafeed';
/**
 * 此為 Polling Datafeed，目前仍有需要向後支援的需求
 *
 * @see {createDatafeedStore}
 */
export const createChartDatafeedOfPolling = (agent, datafeed, 
/** E.g. `5000` equals 5s, defaults to `5000` */
updateFrequency) => {
    const _datafeed = new UDFCompatibleDatafeed(datafeed, updateFrequency ?? 5000, agent);
    return _datafeed;
};
