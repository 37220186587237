export var SocketChannel;
(function (SocketChannel) {
    /** Old Version */
    // 'tw_futures' = 'tw_futures',
    // 'tw_options' = 'tw_op',
    // 'tw_stocks_50' = 'tw_stock',
    // 'tw_stocks_all' = 'tw_stocks_all',
    // 'os_futures' = 'os_fut',
    /** Next Version */
    SocketChannel["tw_futures"] = "tw_futures";
    SocketChannel["tw_options"] = "tw_options";
    SocketChannel["tw_stocks_50"] = "tw_stocks_50";
    SocketChannel["tw_stocks_all"] = "tw_stocks_all";
    SocketChannel["os_futures"] = "os_futures";
})(SocketChannel || (SocketChannel = {}));
